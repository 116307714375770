import React, { useState } from "react";
import axios from "axios";
import config from "../../../../config";
import '../_styles/RequestInfoForm.css';

const RequestInfoForm = ({ closeModal, floorplan, contactEmail, unitFloor }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [countdown, setCountdown] = useState(5);

  const validateForm = () => {
    const newErrors = {};
    if (!name) newErrors.name = "Name is required";
    if (!email) newErrors.email = "Email is required";
    if (!phone) newErrors.phone = "Phone number is required";
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const emailData = {
      from: `"Zentro App" <contact@pureblink.com>`,
      to: 'alejandro@pureblink.com',
      subject: `Request Information for ${floorplan.fp_name}`,
      message: `Name: ${name}\nEmail: ${email}\nPhone: ${phone}\nFloorplan: ${floorplan.fp_name}\nUnit: ${unitFloor}`,
    };

    try {
      await axios.post(
        `${config.API_URL}/service/send-email`,
        emailData,
        {
          headers: {
            apikey: config.API_KEY,
            "Content-Type": "application/json",
          },
        }
      );

      setSuccessMessage("Email sent successfully! We will contact you shortly.");


      let countdownInterval = setInterval(() => {
        setCountdown((prev) => {
          if (prev === 1) {
            clearInterval(countdownInterval); 
            closeModal(); 
          }
          return prev - 1;
        });
      }, 1000);
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-white p-6 shadow-lg w-full max-w-3xl">
        <div className="flex justify-between items-center flex-col text-center relative pt-10">
          <button
            className="text-gray-500 hover:text-gray-700 absolute top-0 right-0 close-btn close-modal"
            onClick={closeModal}
          >
            &times;
          </button>
          <h2 className="text-xl font-bold">REQUEST INFORMATION</h2>
          <p className="italic uppercase">
            Leave us your e-mail address to send you the details
          </p>
        </div>

        <hr className="mt-5" />
        {!successMessage ? (
          <form className="flex-col mt-5 request-info-form" onSubmit={handleSubmit}>
            <div className="mt-2 mb-5">
              <label className="italic">NAME</label>
              <br />
              <input
                type="text"
                name="name"
                className="form-input px-4 py-1 w-full border border-slate-300"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {errors.name && (
                <p className="text-red-500 text-xs mt-1">{errors.name}</p>
              )}
            </div>

            <div className="mt-2 mb-5">
              <label className="italic">EMAIL</label>
              <br />
              <input
                type="email"
                name="email"
                className="form-input px-4 py-1 w-full border border-slate-300"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {errors.email && (
                <p className="text-red-500 text-xs mt-1">{errors.email}</p>
              )}
            </div>

            <div className="mt-2 mb-5">
              <label className="italic">PHONE</label>
              <br />
              <input
                type="phone"
                name="phone"
                className="form-input px-4 py-1 w-full border border-slate-300"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              {errors.phone && (
                <p className="text-red-500 text-xs mt-1">{errors.phone}</p>
              )}
            </div>

            <div className="mt-2 mb-5 text-center">
              <input
                type="submit"
                value="REQUEST INFORMATION"
                className="btn btn-primary black w-full"
              />
            </div>
          </form>
        ) : (
          <div className="text-center">
            <p className="text-green-600 text-lg">{successMessage}</p>
            <p className="text-gray-500 mt-2">
              Closing form in {countdown}...
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default RequestInfoForm;

import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import slugify from "react-slugify";
import { getStyleObjectFromString } from "../../../../plugins/FormatStyle.js";
import "./gallery.css";

const FullScreenSlider = ({ content, colors, sugpage }) => {
  const { projectId } = useParams();
  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = useMemo(() => {
    return Array.isArray(content?.floatSquare_item)
      ? content.floatSquare_item
      : [content.floatSquare_item];
  }, [content]);

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? slides.length - 1 : prevSlide - 1
    );
  };

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === slides.length - 1 ? 0 : prevSlide + 1
    );
  };

  // Calcula los estilos una sola vez
  const textStyle = useMemo(
    () => getStyleObjectFromString(colors?.textColor_0_0 || ""),
    [colors]
  );

  const titleStyle = useMemo(
    () =>
      getStyleObjectFromString({
        ...colors?.textColor_0_0,
        ...colors?.titleColor_1_0,
        ...colors?.title,
      }),
    [colors]
  );

  const subtitleStyle = useMemo(
    () =>
      getStyleObjectFromString(
        colors?.subtitleColor_0_0 || colors?.subtitleColor_1_0
      ),
    [colors]
  );

  const ctaStyle = useMemo(
    () =>
      getStyleObjectFromString({
        ...colors?.ctaColor_0_0,
        ...colors?.ctaColor_1_0,
      }),
    [colors]
  );

  const RenderSlideContent = (item) => {
    const {
      floatSquare_title: title,
      floatSquare_subtitle: subtitle,
      floatSquare_text: text,
      floatSquare_button: button,
      logo,
    } = item.floatSquare_content;

    return (
      <div
        className={`text-slide absolute flex-wrap ${
          item.textboxPosition !== "Array"
            ? item.textboxPosition.toLowerCase()
            : "left"
        } 
        ${colors?.textColor_0_0?.at.replace(
          ".",
          ""
        )} ${colors?.textColor_1_0?.at.replace(".", "")}`}
        style={{
          ...(item.textboxbg
            ? {
                backgroundImage: `url(${item.textboxbg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }
            : getStyleObjectFromString(
                colors?.txtBgColor_0_0 || colors?.txtBgColor_1_0
              )),
        }}
      >
        {logo?.urlMedium && !title ? (
          <img
            className={`logo-slide ${item.floatSquare_content.floatSquare_logo_align}`}
            src={logo.urlMedium}
            alt="Logo"
          />
        ) : (
          <>
            <h2 className="title" style={titleStyle}>
              {title}
            </h2>
            <p style={subtitleStyle}>{subtitle}</p>
          </>
        )}

        {text && (
          <p className="h-fit mt-9 mb-5" style={textStyle}>
            {text}
          </p>
        )}

        {typeof button?.["floatSquare_button-title"] === "string" && (
          <Link
            to={`/project/${projectId}${
              sugpage ? `/${sugpage}/` : "/"
            }${slugify(button["floatSquare_button-title"])}`}
            className="cta-slider block w-auto"
            style={ctaStyle}
          >
            {button["floatSquare_button-title"]}
          </Link>
        )}
      </div>
    );
  };

  return (
    <div className="w-full h-screen overflow-hidden relative">
      {slides.length > 0 &&
        slides.map((item, index) => (
          <div
            key={index}
            className={`absolute inset-0 transition-opacity duration-1000 ${
              index === currentSlide ? "opacity-100" : "opacity-0"
            }`}
            style={{
              backgroundImage: `url(${item.floatSquare_bg})`,
              backgroundSize: "cover",
              backgroundPosition: "center center",
            }}
          >
            {RenderSlideContent(item)}
          </div>
        ))}

      {slides.length > 1 && (
        <>
          <button
            onClick={prevSlide}
            className="arrow absolute top-1/2 left-4 transform -translate-y-1/2 bg-white bg-opacity-50 hover:bg-opacity-100 text-black font-bold py-2 px-4 rounded-full"
            aria-label="Previous Slide"
          >
            &#8592;
          </button>

          <button
            onClick={nextSlide}
            className="arrow absolute top-1/2 right-4 transform -translate-y-1/2 bg-white bg-opacity-50 hover:bg-opacity-100 text-black font-bold py-2 px-4 rounded-full"
            aria-label="Next Slide"
          >
            &#8594;
          </button>
        </>
      )}
    </div>
  );
};

export default FullScreenSlider;

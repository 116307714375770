/* eslint-disable no-loop-func */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
// General Variables

import * as glMatrix from "gl-matrix";
import TWEEN from "@tweenjs/tween.js";
import Sketchfab from "@sketchfab/viewer-api";
import { getProjects } from './../../db';
export default class MasterSketch {
  constructor(
    viewer,
    modelUrl,
    setupCameras,
    units,
    nodeGroupName,
    sketchStyle,
    projectId
  ) {
    this.projectId = projectId
    this.modelUrl = modelUrl;
    this.apiSketch = Sketchfab;
    this.glMatrix = glMatrix;
    this.viewer = viewer;
    this.sketchStyle = sketchStyle;
    this.clickCallback = null;
    this.TWEEN = TWEEN;
    this.Sketchfab = Sketchfab;
    this.getHostpotData = setupCameras.hotsdata;
    this.getGeneralCameraSketch = setupCameras.general_camera;
    this.getOrientationCameraSketch = setupCameras.orientation_camera;
    this.getCameraLimitSketch = setupCameras.camera_limits;
    this.getBoxAniOriSketch = setupCameras.ani_orientation;
    this.buildingName = setupCameras.building_name;
    this.getMapBuilding = new Map(setupCameras.map_building);
    this.initSketch();
    this.units = units;
    this.nodeGroupName = nodeGroupName;
    this.objectBoxes = [];
    // Enlazar funciones al contexto de la instancia
    this.objHotspot3d = {};
    this.objectMaterial = {};
    this.boxesActive = {};
    this.counterMaterial = 0;
    this.tweenVar = {};
    this.boxSelected = "";
    this.activeTransition = false;
    this.useLoadBar = false;
    this.BuildingActual = "";
    this.labelIcon = "";
    this.countCalls = 0;
    // Boxes Variables
    this.allBoxesName = [];
    this.allBoxes = [this.allBoxesName, [""]];
    this.camMap = new Map([
      ["N", 0],
      ["N2", 0],
      ["S", 0],
      ["W", 0],
      ["E", 0],
      ["NE", 0],
      ["SE", 0],
      ["NW", 0],
      ["SW", 0],
    ]);
    this.counter = 0;

    // Camera Variables
    this.annoCamObject = {};
    this.camera3DSection = {};
    this.annoOriObject = {};
    this.camLimitsObject = {};
    this.boxOriPos = {};
    this.priorityOrientation = "";

    // iPad Camera Variables
    this.currentCamera = {
      position: [0, 0, 0],
      target: [0, 0, 0],
    };
    this.pointA = [0, 0, 0];
    this.pointB = [0, 0, 0];
    this.rangeMin = 0;
    this.rangeMax = 0;
    this.unrealIsMoving = true;
    this.selectedUnit = false;
    this.startYaw = 0;
    this.startPitch = 0;
    this.activeTransition = false;
  }

  async initSketch() {
    let client = new Sketchfab("1.12.1", this.viewer);

    client.init(this.modelUrl, {
      success: (api) => {
        this.apiSketch = api;
        this.apiSketch.load();
        this.apiSketch.start();

        this.apiSketch.addEventListener("viewerready", async () => {
          console.log("Viewer is ready v1.0");

          this.apiSketch.removeAllAnnotations();
          try {
            const nodes = await new Promise((resolve, reject) => {
              api.getNodeMap((err, nodes) => {
                if (err) {
                  reject(err);
                } else {
                  resolve(nodes);
                }
              });
            });

            setTimeout(() => {
              this.getBoxesInGraph(nodes);
            }, 2200);
          } catch (err) {
            console.error(err);
          }
        });

        this.apiSketch.pause((err) => {});

        api.addEventListener(
          "click",
          async (info) => {
            if (!info.instanceID) return;
            const storedProjects = await getProjects();
            const currentProject = storedProjects.find(
              (project) => project.id === this.projectId
            );

            const filter = await this.findNodeSketch(info.instanceID, false, currentProject.range_floors_filter);

            if (filter.unitsShow?.length > 0) {
              await this.filterBoxSketch(
                [filter.unitsShow, []],
                [this.sketchStyle.unit_color, this.sketchStyle.available_color],
                [this.sketchStyle.unit_opacity, this.sketchStyle.fp_opacity],
                false,
                info.instanceID - 2
              );

              let getRenderUnit = this.getUnitRenderName(info.instanceID - 2);
              let getFloorUnit = this.getUnitFloor(info.instanceID - 2);
              let getUnitId = this.getUnitId(info.instanceID - 2);
              this.clickCallback(
                filter.targetFpId,
                getRenderUnit,
                getFloorUnit,
                getUnitId
              );
              return filter.targetFpId;
            }
          },
          { pick: "slow" }
        );

        api.addEventListener("camerastart", () => {
          this.handleCameraMovement();
        });

        api.addEventListener("camerastop", () => {
          this.handleCameraMovement(false);
        });
      },
      error: () => {
        return "Viewer error";
      },
      annotation_tooltip_visible: 0,
      annotations_visible: 0,
      double_click: 0,
      ui_controls: 0,
      ui_infos: 0,
      ui_settings: 0,
      ui_watermark: 0,
      ui_stop: 0,
      //transparent : 1
      preload: 1,
      merge_materials: 1,
      graph_optimizer: 0,
      material_packing: 1,
      ui_loading: this.useLoadBar,
    });
  }

  /**
   * Initializes and starts the Sketchfab viewer with the provided model URL.
   *
   * @param {string} modelUrl - The URL of the model to be loaded in the viewer.
   * @returns {Promise} A promise that resolves when the viewer is ready and the boxes in the graph have been retrieved, or rejects with an error.
   */
  async onClick(callback) {
    this.clickCallback = callback;
  }

  async getBoxesInGraph(sketchGraph) {
    sketchGraph = sketchGraph[2].children;
    for (const key in sketchGraph) {
      if (sketchGraph.hasOwnProperty(key)) {
        let stringNoderGroup = new RegExp(this.nodeGroupName, "g");
        let coincidence = stringNoderGroup;
        //  /^.*(QN_PARENT|Unit_container|S_Parent_Units|Units).*$/gm;
        var nodename = sketchGraph[key].name;
        let result = coincidence.exec(nodename);

        if (result !== null) {
          var nodeArray = sketchGraph[key].children;

          await this.addBoxes(nodeArray);
          break;
        } else if (sketchGraph[key].children.length > 0) {
          for (let c = 0; c < sketchGraph[key].children.length; c++) {
            let childrenObj = sketchGraph[key].children[c];
            let childNodename = childrenObj.name;
            let childResult = coincidence.exec(childNodename);
            if (childResult !== null) {
              var nodeArrayChild = childrenObj.children;
              await this.addBoxes(nodeArrayChild);
              break;
            }
          }
        } else {
          console.warn("No se encuentran las unidades para mostrar");
        }
      }
    }

    return await this.assignObjects();
  }

  /**
   * Handles camera movement.
   *
   * @param {boolean} [isStart=true] - Indicates if it's the start of camera movement.
   */
  handleCameraMovement(t = !0) {
    this.apiSketch.getCameraLookAt((i, e) => {
      if (i) {
        console.error("Error fetching camera position:", i);
        return;
      }
      this.pointB = e.position;
      let a = this.pointA[0] - this.pointB[0];
      let s = this.pointA[1] - this.pointB[1];
      let n = this.pointA[2] - this.pointB[2];
      this.startYaw = this.radians_to_degrees(Math.atan2(s, a));
      let o = this.radians_to_degrees(Math.atan2(Math.sqrt(s * s + a * a), n));
      this.startPitch = this.mapRange(o, 0, 180, -90, 90);
      if (!t) {
        this.radians_to_degrees(Math.atan2(s, a));
        this.mapRange(o, 0, 180, -90, 90);
        this.getZoomValue();
      }
    });
  }

  handleZoomOut = () => {
    this.transformCameraSketch(this.buildingName); // Obtiene la posición actual de la cámara
  };

  getUnitId(instanceID) {
    return Object.values(this.objectBoxes).find(
      (box) => box.instanceID === instanceID
    )?.unit_id;
  }


  getNodeId(unitName) {
    return Object.values(this.objectBoxes).find(
      (box) => box.name === unitName
    )?.instanceID;
  }

  /**
   * Retrieves the unit floor associated with the given instance ID.
   *
   * @param {string} instanceID - The ID of the instance.
   * @returns {string|undefined} - The unit floor associated with the instance ID, or undefined if not found.
   */
  getUnitFloor(instanceID) {
    return Object.values(this.objectBoxes).find(
      (box) => box.instanceID === instanceID
    )?.unit_floor;
  }

  /**
   * Retrieves the unit render name for a given instance ID.
   *
   * @param {string} instanceID - The instance ID to search for.
   * @returns {string|undefined} - The unit render name associated with the instance ID, or undefined if not found.
   */
  getUnitRenderName(instanceID) {
    return Object.values(this.objectBoxes).find(
      (box) => box.instanceID === instanceID
    )?.unit_render_name;
  }

  /**
   * Filters nodes by floorplan ID.
   *
   * @param {number} targetFpId - The target floorplan ID.
   * @returns {Promise<void>} - A promise that resolves when the filtering is complete.
   */
  async filterByFloorplanId(targetFpId, unitName = false, compare = false, floors = false) {
    const filter = this.getNodesByFloorplanId(targetFpId, floors);
    if (filter.length === 0) return;

    const activeUnit = this.getNodeByUnitName(unitName ? unitName : filter[0]);
    if (activeUnit) {
      const show = await this.findNodeSketch(activeUnit + 2, false, floors);
      if(!compare) {
        await this.filterBoxSketch(
          [show.unitsShow, []],
          [this.sketchStyle.unit_color, this.sketchStyle.available_color],
          [this.sketchStyle.unit_opacity, 0.9],
          false,
          activeUnit
        );
      }
      const getRenderUnit = this.getUnitRenderName(activeUnit);
      const getUnitFloor = this.getUnitFloor(activeUnit);
      const getUnitId = this.getUnitId(activeUnit);
      return { getRenderUnit, getUnitFloor, getUnitId };
    }
  }

  /**
   * Retrieves the instance ID of a node based on its unit name.
   *
   * @param {string} unitName - The unit name of the node.
   * @returns {string} - The instance ID of the node.
   */
  getNodeByUnitName(unitName) {
    const instanceNode = Object.values(this.objectBoxes).filter(
      (item) => item.name === unitName
    );
    return instanceNode[0].instanceID;
  }

  /**
   * Retrieves nodes by floorplan ID.
   *
   * @param {string} targetFpId - The target floorplan ID.
   * @returns {Array<string>} - An array of node names.
   */
  getNodesByFloorplanId(targetFpId, floors = false) {
    const unitsShow = Object.values(this.objectBoxes)
      .filter((item) => item.fp_id === targetFpId)
      .map((item) => {
        const unit = this.units.find(
          (unit) => unit.unit_model_name === item.name && (!floors || floors.includes(item.unit_floor))
        );
        return unit && unit.unit_status === "available" ? item.name : null;
      })
      .filter((name) => name !== null);

    return unitsShow;
  }

  /**
   * Adds boxes to the objectBoxes property.
   *
   * @param {Array} nodeArray - An array of nodes.
   * @returns {Promise<void>} - A promise that resolves when all boxes are added.
   */
  async addBoxes(nodeArray) {
    for (const node of nodeArray) {
      const name = node.children[0].name;
      const vector = glMatrix.vec3.create();
      this.objectBoxes[name] = {
        ...node,
        name,
        children: node.children[0].children[0],
        is_active: false,
        building_name: this.buildingName,
        orientation: "SW",
        matName: "SW",
        finalPosition: glMatrix.mat4.getTranslation(vector, node.localMatrix),
      };

      this.apiSketch.hide(this.objectBoxes[name].instanceID);
    }
  }

  /**
   * Retrieves the hotspots in the graph.
   *
   * @param {Object} ShetchGraph - The graph object.
   */
  getHotspotinGrap(ShetchGraph) {
    const HotspotData = this.getHostpotData;
    const coincidence = /^.*(HotspotParent).*$/gm;

    for (const key in ShetchGraph) {
      if (ShetchGraph.hasOwnProperty(key)) {
        const nodename = ShetchGraph[key].name;
        const result = coincidence.exec(nodename);

        if (result !== null) {
          const nodeArray = ShetchGraph[key].children;

          for (let i = 0; i < nodeArray.length; i++) {
            const name = nodeArray[i].children[0].name;
            const hotspot = {
              ...nodeArray[i],
              children: nodeArray[i].children[0].children[0],
              type_hotspot: HotspotData[name].type_hotspot,
              section_id: HotspotData[name].section_id,
              item_id: HotspotData[name].item_id,
              camera_id: HotspotData[name].camera_id,
              galery_id: HotspotData[name].galery_id,
              gallery_index: HotspotData[name].gallery_index,
              unreal_hotspot_id: HotspotData[name].unreal_hotspot_id,
            };

            this.objHotspot3d[name] = hotspot;
            this.apiSketch.hide(hotspot.instanceID, function (err) {});
          }

          break;
        }
      }
    }
  }

  /**
   * Creates materials for nodes.
   *
   * @param {string} m - The name of the color.
   * @param {object} hexColor - The hexadecimal color value.
   * @returns {Promise} A promise that resolves with the created material.
   */
  async createMaterialsForNodes(m, c) {
    return new Promise((resolve) => {
      if (this.objectMaterial[m] === undefined) {
        this.apiSketch.createMaterial(
          {
            channels: {
              AlbedoPBR: { color: [c.r, c.g, c.b] },
              Opacity: { enable: true, factor: 0.8, type: "alphaBlend" },
              SpecularPBR: { enable: true },
            },
            cullFace: "BACK",
            name: m,
          },
          (err, material) => {
            if (err) {
              console.log(`Error creating material ${m}:`, err);
              resolve(null);
            } else {
              if (material) {
                this.objectMaterial[m] = material;
                resolve(material);
              } else {
                console.log(`Material ${m} is undefined.`);
                resolve(null);
              }
            }
          }
        );
      } else {
        resolve(this.objectMaterial[m]);
      }
    });
  }

  /**
   * Filters and manipulates boxes based on provided parameters.
   *
   * @param {Array<Array<number>>} allboxes - The array of boxes to filter.
   * @param {Array<string>} materials - The array of materials to apply to the boxes.
   * @param {Array<number>} opacities - The array of opacities to apply to the boxes.
   * @param {boolean} [cameraFinal=false] - Optional parameter to indicate if the final camera action should be performed.
   * @returns {Promise<void>} - A promise that resolves when the filtering and manipulation is complete.
   */

  async filterBoxSketch(t, i, e, a = false, f = null) {
    let s = [],
      n = {},
      o = 0;
    for (let r = 0; r < t.length; r++) {
      o += t[r].length;
      s.push(t[r].sort());
    }
    for (let h = 0; h < s.length; h++) {
      if (i[h]) {
        await this.createMaterialsForNodes(i[h], this.getRgbColor(i[h]));
      }
      for (let l = 0; l < s[h].length; l++) {
        let c = this.objectBoxes[s[h][l]];
        if (c) {
          n[c.name] = c;
          this.apiSketch.show(c.instanceID, (t) => {
            t && console.log(`Error showing box ${c.name}`);
          });
          if (f !== null && c.instanceID === f) {
            this.priorityOrientation = c.orientation;
            this.assignMaterialBox(c, i[0]);
          } else if (f && c.instanceID !== f) {
            this.assignMaterialBox(c, i[1]);
          } else {
            if (c.is_active) {
              this.assignMaterialBox(c, i[h]);
              this.cameraAverage(c.orientation);
              this.buildingAverage(c.building_name);
              c.is_active = true;
            } else {
              this.boxesActive[c.name] = c;
              this.assignMaterialBox(c, i[h]);
              this.cameraAverage(c.orientation);
              this.buildingAverage(c.building_name);
              this.startAnimationPos(c);
              c.is_active = true;
            }
          }
        }
      }
      this.playAnimationOpa(i[h], e[h], o);
    }

    // Ocultar las cajas que no están en el arreglo `t`
    for (let d in this.boxesActive) {
      if (!n[d]) {
        this.apiSketch.hide(this.boxesActive[d].instanceID, (t) => {
          t && console.log(`Error hiding box ${this.boxesActive[d].name}`);
        });
        delete this.boxesActive[d];
        this.objectBoxes[d].is_active = false;
        this.reverseAnimationOpacity(this.objectBoxes[d]);
      }
    }
    this.cameraFinal(a);
  }

  /**
   * Changes the building for the sketch.
   *
   * @param {string} BuildingName - The name of the building to be set.
   * @returns {void}
   */
  sketchChangeBuilding(BuildingName) {
    this.BuildingActual = BuildingName;
    this.transformCameraSketch(BuildingName, 0.7, true);
  }

  /**
   * Assigns objects to various properties.
   */
  async assignObjects() {
    this.annoCamObject = this.getGeneralCameraSketch;
    this.annoOriObject = this.getOrientationCameraSketch;
    this.camLimitsObject = this.getCameraLimitSketch;
    this.boxOriPos = this.getBoxAniOriSketch;
    this.buildMap = this.getMapBuilding;

    this.sketchChangeBuilding(this.buildingName);
    return await this.onloadBuilding();
  }

  /**
   * Assigns a material to a box.
   *
   * @param {Object} box - The box object.
   * @param {string} colorName - The name of the color.
   */
  async assignMaterialBox(box, materialName) {
    if (box.matName !== materialName) {
      let material = this.objectMaterial[materialName];
      if (!material) {
        material = await this.createMaterialsForNodes(
          materialName,
          this.getRgbColor(materialName)
        );
        this.objectMaterial[materialName] = material;
      }

      this.apiSketch.assignMaterial(box.children, material.id, (err) => {
        if (err) {
          console.log(
            `Error assigning material ${materialName} to box ${box.name}`
          );
        } else {
          box.matName = materialName;
        }
      });
    }
  }
  /**
   * Transforms the camera sketch.
   *
   * @param {string} cameraid - The ID of the camera.
   * @param {number} [time=0.7] - The time for the camera transformation.
   * @param {boolean} [bCam=false] - Indicates if the camera is enabled.
   */
  transformCameraSketch(t, i = 0.7, e = !1) {
    if (this.activeTransition) return;
    t ||= this.buildingName;
    let a = this.annoCamObject[t]?.cam?.pos;
    if (a) {
      this.activeTransition = !0;
      this.apiSketch.setUserInteraction(!1);
      this.apiSketch.setEnableCameraConstraints(!1, {
        preventCameraConstraintsFocus: !0,
      });
      this.apiSketch.setCameraEasing("easeInOutCircle");
      this.apiSketch.setCameraLookAt(a.eye, a.target, i, (e) => {
        if (!e) {
          this.pointA = a.target;
          this.pointB = a.eye;
          this.enableCamLimits(t, 1e3 * i);
        }
        this.activeTransition = !1;
      });
    }
  }
  /**
   * Calculates the average of the building with the given name.
   *
   * @param {string} bpname - The name of the building.
   */
  buildingAverage(bpname) {
    let buil_name = this.buildMap.get(bpname);
    buil_name++;
    this.buildMap.set(bpname, buil_name);
  }

  /**
   * Converts a hexadecimal color code to RGB format.
   *
   * @param {string} hex - The hexadecimal color code.
   * @returns {Object} - An object representing the RGB color with properties r, g, and b.
   */
  getRgbColor(hexColor) {
    const hex = this.getHexadecimalColor(hexColor);
    return {
      r: parseFloat(hex.r / 255),
      g: parseFloat(hex.g / 255),
      b: parseFloat(hex.b / 255),
    };
  }

  /**
   * Converts a hexadecimal color code to its RGB representation.
   *
   * @param {string} hex - The hexadecimal color code to convert.
   * @returns {Object|null} - An object containing the RGB values {r, g, b} if the conversion is successful, or null if the input is invalid.
   */
  getHexadecimalColor(hex) {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => {
      return r + r + g + g + b + b;
    });
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }
  getSketchList(anoList) {
    this.apiSketch.setCameraConstraints(
      this.camLimitsObject.limits,
      function (err) {
        if (!err) {
          this.apiSketch.setEnableCameraConstraints(
            true,
            {
              preventCameraConstraintsFocus: false,
            },
            (err) => {}
          );
        }
      }
    );
  }
  /**
   * Sets the camera orientation based on the provided parameters.
   *
   * @param {string} finalOri - The final orientation value.
   * @param {string} finalBuilding - The final building value.
   * @param {boolean} uniqueBuilding - Indicates if the building is unique.
   * @param {boolean} rCam - Indicates if the camera is rotated.
   */
  setCamOrientation(finalOri, finalBuilding, uniqueBuilding, rCam) {
    let orientationFinal;
    if (uniqueBuilding && this.BuildingActual !== "") {
      if (this.annoOriObject[this.BuildingActual].orientation[finalOri]) {
        if (rCam) {
          orientationFinal =
            this.annoOriObject[this.BuildingActual].orientation["up"];
          this.sketchRotateCamera(finalBuilding, orientationFinal);
        } else {
          orientationFinal =
            this.annoOriObject[this.BuildingActual].orientation[finalOri];
          this.sketchRotateCamera(finalBuilding, orientationFinal);
        }
      }
    } else {
      orientationFinal =
        this.annoOriObject[this.buildingName].orientation[finalOri];
      this.sketchRotateCamera(this.getMapBuilding[0], orientationFinal);
    }
  }

  /**
   * Rotates the camera in the sketch.
   *

  /**
   * Rotates the camera in the sketch.
   *
   * @param {string} buildingName - The name of the building.
   * @param {object} orientation - The camera orientation.
   * @param {number} [time=0.7] - The time in seconds for the camera rotation.
   */
  sketchRotateCamera(buildingName, orientation, time = 0.7) {
    if (orientation !== undefined) {
      this.apiSketch.setEnableCameraConstraints(false, {
        preventCameraConstraintsFocus: true,
      });
      this.apiSketch.setCameraEasing("easeLinear");
      this.apiSketch.setCameraLookAt(
        orientation.eye,
        orientation.target,
        time,
        (err) => {
          if (!err) {
            this.pointA = orientation.target;
            this.enableCamLimits(buildingName, time * 1000);
          }
        }
      );
    }
  }

  /**
   * Finds a node sketch based on the provided instanceNode.
   *
   * @param {string} instanceNode - The instance node to search for.
   * @returns {void}
   */
  async findNodeSketch(instanceNode, fp_id = false, floors = false) {
    // Check if it's a Hotspot
    for (const key in this.objHotspot3d) {
      const hotspot = this.objHotspot3d[key];
      if (hotspot.children.instanceID === instanceNode) {
        this.setHotspotInfo(hotspot);
        this.sendToUE(`3d:hotspot:${hotspot.unreal_hotspot_id}`);
        return [];
      }
    }

    // Check if it's a Unit
    for (const key in this.objectBoxes) {
      const box = this.objectBoxes[key];
      if (
        box.children.instanceID === instanceNode &&
        box.is_active &&
        this.boxSelected !== box
      ) {
        this.selectedUnit = box.name;
        const response = await this.setinfoid(this.selectedUnit, fp_id, floors);
        this.selectedBoxAnimation(box, true);
        if (this.boxSelected) {
          this.selectedBoxAnimation(this.boxSelected, false);
        }
        this.boxSelected = box;
        return response;
      }
    }

    return [];
  }

  /**
   * Sets the info ID and target FP ID.
   *
   * @param {string} id - The ID to set.
   * @param {string} fp_id - The FP ID to set.
   * @returns {Object} - An object containing the units to show and the target FP ID.
   */
  async setinfoid(id, fp_id, floors = false) {
    const targetFpId = this.objectBoxes[id]?.fp_id;
    const unitsShow = this.getNodesByFloorplanId(fp_id || targetFpId, floors);
    return { unitsShow, targetFpId };
  }

  /**
   * Animates the selected box.
   *
   * @param {Object} box - The box object.
   * @param {boolean} start - Indicates whether to start the animation from the initial position or the final position.
   * @returns {void}
   */
  selectedBoxAnimation(box, start) {
    let instanceID = box.instanceID;
    let startPos, finalPos;

    if (start) {
      startPos = box.finalPosition;
      finalPos = this.sumVector(
        this.boxOriPos[box.building_name].ori[box.orientation].vec,
        startPos
      );
      this.apiSketch.translate(instanceID, finalPos, {
        duration: 1.3,
        easing: "easeInOutCircle",
      });
    } else {
      startPos = box.finalPosition;
      this.apiSketch.translate(instanceID, startPos, {
        duration: 1.3,
        easing: "easeInOutCircle",
      });
    }
  }

  /**
   * Enables camera limits for a specific camera.
   *
   * @param {string} cameraid - The ID of the camera.
   * @param {number} [time=1500] - The time in milliseconds to wait before enabling the camera constraints.
   */
  enableCamLimits(cameraid, time = 500) {
    if (this.camLimitsObject[cameraid] !== undefined) {
      this.apiSketch.setCameraConstraints(
        this.camLimitsObject[cameraid].limits,
        (err) => {}
      );
      setTimeout(() => {
        this.apiSketch.setEnableCameraConstraints(
          true,
          {
            preventCameraConstraintsFocus: true,
          },
          (err) => {
            if (!err) {
              this.activeTransition = false;
              this.apiSketch.setUserInteraction(true, () => {});
              this.defineZoomRange(this.camLimitsObject[cameraid].limits);
              this.apiSketch.setCameraConstraints(
                this.camLimitsObject[cameraid].limits,
                (err) => {
                  if (!err) console.log("Camera constraints enabled");
                }
              );
            }
          }
        );
      }, time);
    }
  }

  /**
   * Checks if the camera transition is active.
   * @returns {boolean} True if the camera transition is active, false otherwise.
   */
  isCameraTransition() {
    return this.activeTransition;
  }

  /**
   * Sets the visibility of hotspots based on the provided section_id and item_id.
   * If section_id is empty or matches the hotspot's section_id, and item_id is empty or matches the hotspot's item_id, the hotspot is shown.
   * Otherwise, the hotspot is hidden.
   * @param {string} [section_id="Nan"] - The section ID to filter hotspots. Defaults to "Nan".
   * @param {string} [item_id=""] - The item ID to filter hotspots. Defaults to an empty string.
   */
  setHotspotVisibility(section_id = "Nan", item_id = "") {
    for (let key in this.objHotspot3d) {
      const hotspot = this.objHotspot3d[key];
      const {
        section_id: hotspotSectionId,
        item_id: hotspotItemId,
        instanceID,
      } = hotspot;

      if (hotspotSectionId === section_id || hotspotSectionId === "") {
        if (hotspotItemId === "" && item_id === "") {
          this.apiSketch.show(instanceID, function (err) {});
        } else if (hotspotItemId === item_id) {
          this.apiSketch.show(instanceID, function (err) {});
        } else {
          this.apiSketch.hide(instanceID, function (err) {});
        }
      } else {
        this.apiSketch.hide(instanceID, function (err) {});
      }
    }
  }

  /**
   * Starts the animation for a given box.
   *
   * @param {Object} box - The box object.
   * @param {string} box.instanceID - The instance ID of the box.
   * @param {Array} box.finalPosition - The final position of the box.
   * @param {string} box.building_name - The name of the building.
   * @param {string} box.orientation - The orientation of the box.
   */
  startAnimationPos(box) {
    let instanceID = box.instanceID;
    let finalPos = box.finalPosition;
    if (
      this.boxOriPos[box.building_name] &&
      this.boxOriPos[box.building_name].ori[box.orientation]
    ) {
      var startPos = this.sumVector(
        this.boxOriPos[box.building_name].ori[box.orientation].vec,
        finalPos
      );
      this.apiSketch.translate(instanceID, startPos, (err, translateTo) => {
        if (!err) {
          this.apiSketch.translate(instanceID, finalPos, {
            duration: 1.3,
            easing: "easeInOutCircle",
          });
        }
      });
    }
  }

  /**
   * Calculates the sum of two vectors.
   *
   * @param {Object} vecA - The first vector.
   * @param {Object} vecB - The second vector.
   * @returns {Object} - The sum of the two vectors.
   */
  sumVector(vecA, vecB) {
    const obj = {};
    for (const key in vecA) {
      if (vecA.hasOwnProperty(key)) {
        obj[key] = vecA[key] + vecB[key];
      }
    }
    return obj;
  }
  /**
   * Calculates the average of camera orientation.
   *
   * @param {string} orientation - The camera orientation.
   * @returns {void}
   */
  cameraAverage(orientation) {
    let oriactual = this.camMap.get(orientation);
    oriactual++;
    this.camMap.set(orientation, oriactual);
  }

  /**
   * Plays an animation to change the opacity of a material.
   *
   * @param {string} materialName - The name of the material.
   * @param {number} finalOpa - The final opacity value.
   * @param {number} [time=1500] - The duration of the animation in milliseconds.
   */
  playAnimationOpa(materialName, targetOpacity, duration = 1500) {
    const material = this.objectMaterial[materialName];
    if (!material) {
      console.log(`Material ${materialName} not found for opacity animation.`);
      return;
    }

    if (material.channels.Opacity.factor !== targetOpacity) {
      const startValue = { opacity: material.channels.Opacity.factor };
      const endValue = { opacity: targetOpacity };

      if (this.tweenVar[materialName]) {
        this.tweenVar[materialName].stop();
      }

      const tween = new TWEEN.Tween(startValue)
        .to(endValue, duration)
        .onUpdate(() => {
          material.channels.Opacity.factor = startValue.opacity;
          this.apiSketch.setMaterial(material, () => {});
        })
        .onComplete(() => {
          material.channels.Opacity.factor = endValue.opacity;
          this.apiSketch.setMaterial(material, () => {});
        })
        .start();

      this.tweenVar[materialName] = tween;
    }
  }

  /**
   * Updates the material of the sketch.
   *
   * @param {string} selectMaterial - The selected material.
   */
  updateMaterial(selectMaterial) {
    let updating = true;
    if (updating) {
      updating = false;
      this.apiSketch.setMaterial(selectMaterial, () => {
        updating = true;
      });
    }
  }
  /**
   * Reverses the animation opacity of a box.
   *
   * @param {Object} box - The box object.
   */
  reverseAnimationOpacity(box) {
    if (box === this.boxSelected) {
      this.boxSelected = "";
    }
    this.apiSketch.hide(box.instanceID, (err) => {});
  }

  /**
   * Executes the final camera action.
   *
   * @param {Object} rcam - The rcam object.
   */
  cameraFinal(rcam = "active") {
    let max_value = 0;
    let final_ori;
    let bmax_value = 0;
    let final_b = this.buildingName;
    let act_building = "null";
    let uniqueBuilding = true;

    for (let [key, value] of this.camMap) {
      if (max_value < value) {
        max_value = value;
        final_ori = key;
      }
      this.camMap.set(key, 0);
    }

    for (let [bkey, bvalue] of this.buildMap) {
      if (bmax_value < bvalue) {
        bmax_value = bvalue;
        final_b = bkey;
        if (this.BuildingActual === bkey || act_building === null) {
          act_building = final_b;
          uniqueBuilding = true;
        } else {
          uniqueBuilding = false;
        }
      } else {
        if (act_building !== bkey && bvalue > 0) {
          uniqueBuilding = false;
        }
      }
      this.buildMap.set(bkey, 0);
    }

    if (this.priorityOrientation !== "") {
      final_ori = this.priorityOrientation;
    }
    this.setCamOrientation(final_ori, final_b, uniqueBuilding, rcam);
  }
  /**
   * Maps a value from one range to another range.
   *
   * @param {number} value - The value to be mapped.
   * @param {number} inMin - The minimum value of the input range.
   * @param {number} inMax - The maximum value of the input range.
   * @param {number} outMin - The minimum value of the output range.
   * @param {number} outMax - The maximum value of the output range.
   * @returns {number} - The mapped value.
   */
  mapRange(value, inMin, inMax, outMin, outMax) {
    return ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
  }

  /**
   * Calculates the 3D distance between two points.
   *
   * @param {number[]} a - The coordinates of the first point.
   * @param {number[]} b - The coordinates of the second point.
   * @returns {number} The distance between the two points.
   */
  distance3d(a, b) {
    return Math.sqrt(
      Math.pow(a[0] - b[0], 2) +
        Math.pow(a[1] - b[1], 2) +
        Math.pow(a[2] - b[2], 2)
    );
  }

  /**
   * Clamps a number between two values.
   *
   * @param {number} num - The number to be clamped.
   * @param {number} a - The lower bound of the clamp range.
   * @param {number} b - The upper bound of the clamp range.
   * @returns {number} - The clamped number.
   */
  clampNumber(num, a, b) {
    return Math.max(Math.min(num, Math.max(a, b)), Math.min(a, b));
  }

  defineZoomRange(cameraRange) {
    this.rangeMin = cameraRange.zoomIn;
    this.rangeMax = cameraRange.zoomOut;
  }

  /**
   * Calculates the zoom value based on the distance between two points.
   * @returns {number} The calculated zoom value.
   */
  getZoomValue() {
    const disActual = this.glMatrix.vec3
      .distance(this.pointA, this.pointB)
      .toFixed(2);
    return this.mapRange(disActual, this.rangeMin, this.rangeMax, 0, 5);
  }

  /**
   * Converts radians to degrees.
   *
   * @param {number} radians - The value in radians to be converted.
   * @returns {number} The converted value in degrees.
   */
  radiansToDegrees(radians) {
    const pi = Math.PI;
    return radians * (180 / pi);
  }

  /**
   * Handles the onload event for building.
   */
  async onloadBuilding() {
    this.units.forEach((unit) => {
      if (
        unit.unit_model_name === this.objectBoxes[unit.unit_model_name].name
      ) {
        if (unit.unit_building)
          this.objectBoxes[unit.unit_model_name].building_name =
            unit.unit_building;

        this.objectBoxes[unit.unit_model_name].orientation =
          unit.unit_camera.toUpperCase();
        this.objectBoxes[unit.unit_model_name].fp_id = unit.fp_id;
        this.objectBoxes[unit.unit_model_name].unit_status = unit.unit_status;
        this.objectBoxes[unit.unit_model_name].unit_render_name =
          unit.unit_name[0];
        this.objectBoxes[unit.unit_model_name].unit_floor = unit.unit_floor;
        this.objectBoxes[unit.unit_model_name].unit_id = unit.unit_id;
      }
    });

    return this.filterFPs(true);
  }

  /**
   * Filters the units and calculates the availability of each unit.
   *
   * @param {boolean} [restartcam=false] - Indicates whether to restart the camera.
   * @returns {void}
   */

  async filterFPs(restartcam = false) {
    const filteredUnits = [];
    const filteredFPs = new Set();
    const soldUnits = [];
    const availableUnits = [];
    const availableUnitsID = [];
    const soldUnitsID = [];

    const allModels = {};

    for (const unit of this.units) {
      const unitIncluded = true;

      const availability = unit.unit_status;
      const unitName = unit.unit_model_name;

      allModels[unitName] = unit.unit_id;

      if (unitIncluded && !filteredFPs.has(unit.unit_status)) {
        filteredFPs.add(unit.unit_status);
      }

      if (unitIncluded && !filteredFPs.has(unit.fp_id)) {
        filteredFPs.add(unit.fp_id);
      }
      if (unitIncluded && !filteredUnits.includes(unit.unit_id)) {
        filteredUnits.push(unit.unit_id);

        if (availability === "available") {
          availableUnits.push(unitName);
          availableUnitsID.push(unit.unit_id);
        } else {
          soldUnits.push(unitName);
          soldUnitsID.push(unit.unit_id);
        }
      }
    }

    try {
      await this.displayModelAvailability(
        availableUnits,
        soldUnits,
        this.sketchStyle,
        restartcam
      );
    } catch (error) {}
  }
  /**
   * Displays the model availability.
   *
   * @param {number} a - The number of available units.
   * @param {number} s - The number of sold units.
   * @param {string} c - The color for available units.
   * @param {string} d - The color for sold units.
   * @param {number} o - The opacity for available units.
   * @param {number} y - The opacity for sold units.
   * @param {boolean} [r=false] - Whether to reset the camera or not.
   * @returns {Promise<void>} - A promise that resolves when the model availability is displayed.
   */
  async displayModelAvailability(a, s, k, r = false, i) {
    if (this.boxSelected) this.selectedBoxAnimation(this.boxSelected, false);
    if (i) this.boxSelected = null;
    const unitsArray = [a, s];
    const colorsArray = [k.available_color, k.sold_color];
    const opacityArray = [k.available_opacity, k.sold_opacity];

    try {
      await this.filterBoxSketch(unitsArray, colorsArray, opacityArray, r);

      return true;
    } catch (error) {
      console.log(error);
    }
  }

  async radians_to_degrees(radians) {
    return radians * (180 / Math.PI);
  }
}

import React, { useEffect, useState } from 'react';
import Slider from '../Templates/_Parts/Slide';
import GalleryMasonry from '../Templates/_Parts/GalleryMasonry/index.js';
import Iframe from 'react-iframe';
import Loader from '../Templates/_Parts/Loader/index.js';
import FloorPlans from '../Templates/FloorPlans/index.js';
import LocalAmenities from '../Templates/LocalAmenities/index.js';
import BrokerAssets from '../Templates/BrokerAssets/index.js';

const PageContent = ({ page, colors }) => {
  const [loading, setLoading] = useState(true);
  const [mapCategories, setMapCategories] = useState([]);

  useEffect(() => {
    const adjustIframeHeight = () => {
      
      const iframe = document.querySelector('iframe');
      if (iframe) {
        iframe.style.height = `${window.innerHeight}px`;
      }
    };

    if (page?.subsection?.template === 'iframe') {
      adjustIframeHeight();
      window.addEventListener('resize', adjustIframeHeight);
    }

    return () => {
      window.removeEventListener('resize', adjustIframeHeight);
    };

  }, [page]);

  useEffect(() => {
    if(page?.subsection?.template === 'localAmenities') {
      setMapCategories(page?.subsection?.categoria);
    }
  }, [page]);

  useEffect(() => {
    if (page?.subsection?.template === 'iframe' && page.subsection.url_iframe) {
      setLoading(true);  // Reset loader state when iframe URL changes
    }
  }, [page?.subsection?.url_iframe, page?.subsection?.template]);

  if (!page) return <p>Loading...</p>;

  return (
    <>
      {page?.subsection?.template === 'floatSquare' && page.subsection.floatSquare_item && (
        <Slider content={page.subsection} colors={colors} />
      )}

      {page?.subsection?.template === 'masonry' && page.subsection.images.image.length > 0 && (
        <GalleryMasonry images={page.subsection.images} />
      )}

      {page?.subsection?.template === 'iframe' && page.subsection.url_iframe && (
        <div className="iframe-container h-screen relative">
          {loading && <Loader />}
          <Iframe
            url={page.subsection.url_iframe.replace(/&amp;/g, '&')}
            width="100%"
            height="100%"
            onLoad={() => setLoading(false)}
            display="block"
            position="absolute"
            aria-label="Content iframe"
          />
        </div>
      )}

      {page?.subsection?.template === 'floorplans' &&  (
        <FloorPlans />
      )}

      {page?.subsection?.template === 'localAmenities' && (
        <LocalAmenities mapCategories={mapCategories} colors={colors} project={page.subsection.project}/>
      )}

      {page?.subsection?.template === 'assets' && (
        <BrokerAssets section={page?.subsection} colors={colors} />
      )}
    </>
  );
};

export default PageContent;

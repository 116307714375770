import React, { useState, useEffect } from "react";
import Masonry from "react-masonry-css";
import Loader from "../Loader";
import "./MasonryGallery.css";

const MasonryGallery = ({ images }) => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [loadedImagesCount, setLoadedImagesCount] = useState(0);
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);

  const imageArray = images?.image || images;

  useEffect(() => {
    if (imageArray.length === 0) return;
    setLoadedImagesCount(0);
    setAllImagesLoaded(false);

    const imagePromises = imageArray.map(
      (image, index) =>
        new Promise((resolve) => {
          const img = new Image();
          img.src = image.urlLarge;
          img.onload = () => resolve(index);
          img.onerror = () => resolve(index);
        })
    );

    Promise.all(imagePromises).then((indexes) => {
      setLoadedImagesCount(indexes.length);
      setAllImagesLoaded(true);
    });
  }, [imageArray]);

  const openLightbox = (image) => {
    setCurrentImage(image);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setCurrentImage(null);
  };

  return (
    <>
      {!allImagesLoaded && <Loader />}
      <Masonry
        breakpointCols={{
          default: imageArray.length <= 5 ? 2 : 4,
          1200: 3,
          768: 1,
        }}
        className="masonry-grid"
        columnClassName="masonry-grid_column"
      >
        {imageArray.map((image, index) => (
          <div key={index} className="masonry-item">
            <img
              src={image.urlLarge}
              alt={image.alt || ""}
              className={`cursor-pointer ${
                loadedImagesCount > index ? "opacity-100" : "opacity-0"
              }`}
              onClick={() => openLightbox(image)}
              style={{ transition: "opacity 0.3s ease-in-out" }}
              loading="lazy"
            />
          </div>
        ))}
      </Masonry>

      {lightboxOpen && currentImage && (
        <div className="lightbox fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50">
          <button
            className="absolute top-4 right-4 text-white text-2xl"
            onClick={closeLightbox}
          >
            &times;
          </button>
          <img
            src={currentImage.urlLarge}
            alt={currentImage.alt || ""}
            className="w-11/12-full"
          />
        </div>
      )}
    </>
  );
};

export default MasonryGallery;

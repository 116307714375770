import React, { useState, useEffect, useRef } from "react";
import { getStyleObjectFromString } from "../../../../plugins/FormatStyle.js";
import { Link, useParams } from "react-router-dom";
import slugify from "react-slugify";

import "./menu.css";

const Menu = ({ styles, bgMenu, content }) => {

  const { projectId } = useParams();
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeSubmenu, setActiveSubmenu] = useState(null);

  const submenuRefs = useRef([]); // To store references to submenus

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleSubmenu = (index) => {
    setActiveSubmenu((prevIndex) => (prevIndex === index ? null : index));
  };

  useEffect(() => {
    if (menuOpen) {
      const menuAnimationDuration = 200;
      const itemDelay = 100;

      setTimeout(() => {
        const items = document.querySelectorAll(".menuItem");
        items.forEach((item, index) => {
          setTimeout(() => {
            item.classList.add("show-item");
          }, index * itemDelay);
        });
      }, menuAnimationDuration);
    } else {
      const items = document.querySelectorAll(".menuItem");
      items.forEach((item) => {
        item.classList.remove("show-item");
      });
    }
  }, [menuOpen]);

  useEffect(() => {
    submenuRefs.current.forEach((submenu, index) => {
      if (submenu) {
        submenu.style.maxHeight = activeSubmenu === index ? `${submenu.scrollHeight}px` : "0";
      }
    });
  }, [activeSubmenu]);

  const handleItemClick = (e, item) => {
    if (!item.subsection || item.subsection.length <= 1) {

      setMenuOpen(false);
    }
  };

  return (
    <div className="container">
      <button
        className="burguer"
        style={getStyleObjectFromString(styles.bg_burguer)}
        onClick={toggleMenu}
      >
        ☰
      </button>

      <div
        className={`animated-menu ${menuOpen ? "open" : ""} overlay bgMenu`}
        style={{
          ...getStyleObjectFromString(styles.mainMenu),
          backgroundImage: `url(${bgMenu})`,
        }}
      >
        <button
          className="close"
          style={getStyleObjectFromString(styles.bg_burguer)}
          onClick={toggleMenu}
        >
          &times;
        </button>
        <nav className="menu">
          <ul>
            {content &&
              content.section.map((item, index) => (
                <div key={index}>
                  <Link
                    className="menuItem"
                    to={
                      item.subsection && item.subsection.length > 1
                        ? "#"
                        : `/project/${projectId}/${slugify(item.title)}`
                    }
                    onClick={(e) => {
                      if (item.subsection && item.subsection.length > 1) {
                        e.preventDefault();
                        toggleSubmenu(index);
                      } else {
                        handleItemClick(e, item);
                        toggleMenu();
                      }
                    }}
                    style={getStyleObjectFromString({
                      ...styles.mainMenuItem,
                      ...styles.menuItemFont,
                    })}
                  >
                    {item.title}
                    {item.subsection && item.subsection.length > 0 && (
                      <div
                        className={`arrow-down ${
                          activeSubmenu === index ? "rotate" : ""
                        }`}
                        style={{
                          ...(styles.arrowMenu["background-image"]
                            ? {
                                backgroundImage: `url(${styles.arrowMenu["background-image"]})`,
                                
                              }
                            : {})
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleSubmenu(index);
                        }}
                      ></div>
                    )}
                  </Link>

                  {/* Submenu */}
                  <div
                    ref={(el) => (submenuRefs.current[index] = el)}
                    className={`submenu ${activeSubmenu === index ? "show" : ""}`}
                  >
                    {item.subsection && item.subsection.length > 0 &&
                      item.subsection.map((subitem, subIndex) => (
                        <Link
                          key={subIndex}
                          className="submenuItem"
                          to={`/project/${projectId}/${slugify(
                            item.title
                          )}/${slugify(subitem.titleSubsection)}`}
                          onClick={() => setMenuOpen(false)} 
                          style={getStyleObjectFromString({
                            ...styles.submenuItem, 
                            ...styles.mainSubmenu,
                            ...styles.menuItemFont
                          })}
                        >
                          {subitem.titleSubsection.replace(/&amp;/g, "&")}
                        </Link>
                      ))}
                  </div>
                </div>
              ))}
          </ul>
        </nav>
        <main></main>
        <div id="credit">
          <a href="https://zentro.vision/">
            <img
              src="https://zentro.s3.us-west-2.amazonaws.com/wp-content/uploads/Zentro_logo_black.png"
              alt=""
              width="100px"
            ></img>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Menu;

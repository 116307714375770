import React, { useState, useRef, useEffect } from "react";
import '../_styles/PanoramaView.css';

const PanoramaView = ({ data, onClosePanorama }) => {
  const {
    view_image,
    view_thumbnail,
    view_reference_point,
    view_total_degrees,
  } = data;

  const [dragging, setDragging] = useState(false);
  const [position, setPosition] = useState(0);
  const [compass, setCompass] = useState(parseInt(view_reference_point) || 0);
  const [focusPosition, setFocusPosition] = useState(0);
  const containerRef = useRef(null);

  const FOCUS_WIDTH_PERCENT = 20; // Ancho del foco en porcentaje
  const VIEW_IMAGE_WIDTH = 5000; // Un ancho estimado o conocido de la imagen panorámica (ajústalo si es necesario)

  // Maneja los eventos de arrastre (mouse y touch)
  const handleDragStart = (e) => {
    e.preventDefault(); // Previene comportamientos inesperados
    setDragging(true);
    handleThumbnailDrag(e);
  };

  const handleDragEnd = () => {
    setDragging(false);
  };

  const handleDragMove = (e) => {
    if (dragging) {
      handleThumbnailDrag(e);
    }
  };

  const handleThumbnailDrag = (e) => {
    if (containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const thumbnailWidth = containerRef.current.offsetWidth;

      let mouseX;

      // Verifica si es un evento táctil o de ratón
      if (e.type.includes('touch')) {
        mouseX = e.touches[0].clientX - containerRect.left;
      } else {
        mouseX = e.clientX - containerRect.left;
      }

      // Limita el valor de mouseX entre 0 y thumbnailWidth para que no se salga
      mouseX = Math.max(0, Math.min(mouseX, thumbnailWidth));

      const clickPercentage = (mouseX / thumbnailWidth) * 100;

      // Calcula la nueva posición de la imagen panorámica
      const newPosition = (clickPercentage / 100) * VIEW_IMAGE_WIDTH;

      // Ajusta la posición de la panorámica y el foco
      setPosition(-newPosition);

      // Ajusta la brújula
      const referencePoint = parseInt(view_reference_point) || 0;
      const newCompassValue = (clickPercentage / 100) * parseInt(view_total_degrees) + referencePoint;
      setCompass(newCompassValue % 360);

      // Limita el foco para que no se desborde a la derecha
      const maxFocusPosition = 100 - FOCUS_WIDTH_PERCENT;
      setFocusPosition(Math.min(clickPercentage, maxFocusPosition));
    }
  };

  // Añadir eventos globales para ratón o touch fuera del componente
  useEffect(() => {
    if (dragging) {
      window.addEventListener("mousemove", handleDragMove);
      window.addEventListener("mouseup", handleDragEnd);
      window.addEventListener("touchmove", handleDragMove);
      window.addEventListener("touchend", handleDragEnd);
    } else {
      window.removeEventListener("mousemove", handleDragMove);
      window.removeEventListener("mouseup", handleDragEnd);
      window.removeEventListener("touchmove", handleDragMove);
      window.removeEventListener("touchend", handleDragEnd);
    }
    return () => {
      window.removeEventListener("mousemove", handleDragMove);
      window.removeEventListener("mouseup", handleDragEnd);
      window.removeEventListener("touchmove", handleDragMove);
      window.removeEventListener("touchend", handleDragEnd);
    };
  }, [dragging]);

  return (
    <div className="panorama-overlay">
      {/* Botón de cierre */}
      <button className="close-button-panorama" onClick={onClosePanorama}>X</button>

      {/* Contenedor de dos filas */}
      <div className="panorama-container">
        {/* Primera fila - Imagen panorámica */}
        <div
          className="panorama-image"
          style={{
            backgroundImage: `url(${view_image})`,
            backgroundPositionX: `${position}px`, 
            transition: dragging ? "none" : "background-position 0.3s ease", // Transición suave solo cuando no arrastras
          }}
        />

        {/* Segunda fila - Miniatura */}
        <div
          className="thumbnail-container"
          ref={containerRef}
          onMouseDown={handleDragStart}
          onTouchStart={handleDragStart}
          style={{
            backgroundImage: `url(${view_thumbnail})`,
          }}
        >
          {/* Indicador de foco */}
          <div
            className="thumbnail-focus"
            style={{
              left: `${focusPosition}%`,
            }}
          />
        </div>
      </div>

      {/* Brújula */}
      <div className="compass-container">
        <div className="compass" style={{ transform: `rotate(${compass}deg)` }}>
          <div className="compass-arrow north"><span>N</span></div>
          <div className="compass-arrow east"><span>E</span></div>
          <div className="compass-arrow south"><span>S</span></div>
          <div className="compass-arrow west"><span>W</span></div>
        </div>
      </div>
    </div>
  );
};

export default PanoramaView;
